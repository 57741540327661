!function(t, n) {
  window.ElspwjQuery ? n(t, window.ElspwjQuery) : "function" == typeof define && define.amd ? define(["jquery"], function(a) {
      return n(t, a)
  }) : "object" == typeof exports ? n(t, require("jquery")) : n(t, t.jQuery || t.Zepto)
}(this, function(t, n) {
  "use strict";
  var a, e, i, o = "remodal", s = t.REMODAL_GLOBALS && t.REMODAL_GLOBALS.NAMESPACE || o, r = n.map(["animationstart", "webkitAnimationStart", "MSAnimationStart", "oAnimationStart"], function(t) {
      return t + "." + s
  }).join(" "), l = n.map(["animationend", "webkitAnimationEnd", "MSAnimationEnd", "oAnimationEnd"], function(t) {
      return t + "." + s
  }).join(" "), d = n.extend({
      hashTracking: !0,
      closeOnConfirm: !0,
      closeOnCancel: !0,
      closeOnEscape: !0,
      closeOnOutsideClick: !0,
      modifier: "",
      appendTo: null
  }, t.REMODAL_GLOBALS && t.REMODAL_GLOBALS.DEFAULTS), c = {
      CLOSING: "closing",
      CLOSED: "closed",
      OPENING: "opening",
      OPENED: "opened"
  }, p = {
      CONFIRMATION: "confirmation",
      CANCELLATION: "cancellation"
  }, m = void 0 !== (i = document.createElement("div").style).animationName || void 0 !== i.WebkitAnimationName || void 0 !== i.MozAnimationName || void 0 !== i.msAnimationName || void 0 !== i.OAnimationName, u = /iPad|iPhone|iPod/.test(navigator.platform);
  function f(t) {
      if (m && "none" === t.css("animation-name") && "none" === t.css("-webkit-animation-name") && "none" === t.css("-moz-animation-name") && "none" === t.css("-o-animation-name") && "none" === t.css("-ms-animation-name"))
          return 0;
      var n, a, e, i, o = t.css("animation-duration") || t.css("-webkit-animation-duration") || t.css("-moz-animation-duration") || t.css("-o-animation-duration") || t.css("-ms-animation-duration") || "0s", s = t.css("animation-delay") || t.css("-webkit-animation-delay") || t.css("-moz-animation-delay") || t.css("-o-animation-delay") || t.css("-ms-animation-delay") || "0s", r = t.css("animation-iteration-count") || t.css("-webkit-animation-iteration-count") || t.css("-moz-animation-iteration-count") || t.css("-o-animation-iteration-count") || t.css("-ms-animation-iteration-count") || "1";
      for (i = 0,
      o = o.split(", "),
      s = s.split(", "),
      r = r.split(", "),
      a = o.length,
      n = Number.NEGATIVE_INFINITY; i < a; i++)
          (e = parseFloat(o[i]) * parseInt(r[i], 10) + parseFloat(s[i])) > n && (n = e);
      return n
  }
  function h() {
      if (n(document).height() <= n(window).height())
          return 0;
      var t, a, e = document.createElement("div"), i = document.createElement("div");
      return e.style.visibility = "hidden",
      e.style.width = "100px",
      document.body.appendChild(e),
      t = e.offsetWidth,
      e.style.overflow = "scroll",
      i.style.width = "100%",
      e.appendChild(i),
      a = i.offsetWidth,
      e.parentNode.removeChild(e),
      t - a
  }
  function g() {
      if (!u) {
          var t, a, e = n("html"), i = E("is-locked");
          e.hasClass(i) && (t = parseInt((a = n(document.body)).css("padding-right"), 10) - h(),
          a.css("padding-right", t + "px"),
          e.removeClass(i))
      }
  }
  function v(t, n, a, e) {
      var i = E("is", n)
        , o = [E("is", c.CLOSING), E("is", c.OPENING), E("is", c.CLOSED), E("is", c.OPENED)].join(" ");
      t.$bg.removeClass(o).addClass(i),
      t.$overlay.removeClass(o).addClass(i),
      t.$wrapper.removeClass(o).addClass(i),
      t.$modal.removeClass(o).addClass(i),
      t.state = n,
      a || t.$modal.trigger({
          type: n,
          reason: e
      }, [{
          reason: e
      }])
  }
  function C(t, a, e) {
      var i = 0
        , o = function(t) {
          t.target === this && i++
      }
        , s = function(t) {
          t.target === this && 0 == --i && (n.each(["$bg", "$overlay", "$wrapper", "$modal"], function(t, n) {
              e[n].off(r + " " + l)
          }),
          a())
      };
      n.each(["$bg", "$overlay", "$wrapper", "$modal"], function(t, n) {
          e[n].on(r, o).on(l, s)
      }),
      t(),
      0 === f(e.$bg) && 0 === f(e.$overlay) && 0 === f(e.$wrapper) && 0 === f(e.$modal) && (n.each(["$bg", "$overlay", "$wrapper", "$modal"], function(t, n) {
          e[n].off(r + " " + l)
      }),
      a())
  }
  function O(t) {
      t.state !== c.CLOSED && (n.each(["$bg", "$overlay", "$wrapper", "$modal"], function(n, a) {
          t[a].off(r + " " + l)
      }),
      t.$bg.removeClass(t.settings.modifier),
      t.$overlay.removeClass(t.settings.modifier).hide(),
      t.$wrapper.hide(),
      g(),
      v(t, c.CLOSED, !0))
  }
  function E() {
      for (var t = s, n = 0; n < arguments.length; ++n)
          t += "-" + arguments[n];
      return t
  }
  function y() {
      var t, e, i = location.hash.replace("#", "");
      if (i) {
          try {
              e = n("[data-" + o + '-id="' + i + '"]')
          } catch (s) {}
          e && e.length && (t = n[o].lookup[e.data(o)]) && t.settings.hashTracking && t.open()
      } else
          a && a.state === c.OPENED && a.settings.hashTracking && a.close()
  }
  function N(t, a) {
      var e = n(document.body)
        , i = this;
      i.settings = n.extend({}, d, a),
      i.index = n[o].lookup.push(i) - 1,
      i.state = c.CLOSED,
      i.$overlay = n("." + E("overlay")),
      null !== i.settings.appendTo && i.settings.appendTo.length && (e = n(i.settings.appendTo)),
      i.$overlay.length || (i.$overlay = n("<div>").addClass(E("overlay") + " " + E("is", c.CLOSED)).hide(),
      e.append(i.$overlay)),
      i.$bg = n("." + E("bg")).addClass(E("is", c.CLOSED)),
      i.$modal = t.addClass(s + " " + E("is-initialized") + " " + i.settings.modifier + " " + E("is", c.CLOSED)).attr("tabindex", "-1"),
      i.$wrapper = n("<div>").addClass(E("wrapper") + " " + i.settings.modifier + " " + E("is", c.CLOSED)).hide().append(i.$modal),
      e.append(i.$wrapper),
      i.$wrapper.on("click." + s, "[data-" + o + '-action="close"]', function(t) {
          t.preventDefault(),
          i.close()
      }),
      i.$wrapper.on("click." + s, "[data-" + o + '-action="cancel"]', function(t) {
          t.preventDefault(),
          i.$modal.trigger(p.CANCELLATION),
          i.settings.closeOnCancel && i.close(p.CANCELLATION)
      }),
      i.$wrapper.on("click." + s, "[data-" + o + '-action="confirm"]', function(t) {
          t.preventDefault(),
          i.$modal.trigger(p.CONFIRMATION),
          i.settings.closeOnConfirm && i.close(p.CONFIRMATION)
      }),
      i.$wrapper.on("click." + s, function(t) {
          n(t.target).hasClass(E("wrapper")) && i.settings.closeOnOutsideClick && i.close()
      })
  }
  N.prototype.open = function() {
      var t, i = this;
      i.state !== c.OPENING && i.state !== c.CLOSING && ((t = i.$modal.attr("data-" + o + "-id")) && i.settings.hashTracking && (e = n(window).scrollTop(),
      location.hash = t),
      a && a !== i && O(a),
      a = i,
      function t() {
          if (!u) {
              var a, e, i = n("html"), o = E("is-locked");
              i.hasClass(o) || (a = parseInt((e = n(document.body)).css("padding-right"), 10) + h(),
              e.css("padding-right", a + "px"),
              i.addClass(o))
          }
      }(),
      i.$bg.addClass(i.settings.modifier),
      i.$overlay.addClass(i.settings.modifier).show(),
      i.$wrapper.show().scrollTop(0),
      i.$modal.focus(),
      C(function() {
          v(i, c.OPENING)
      }, function() {
          v(i, c.OPENED)
      }, i))
  }
  ,
  N.prototype.close = function(t) {
      var a = this;
      a.state !== c.OPENING && a.state !== c.CLOSING && a.state !== c.CLOSED && (a.settings.hashTracking && a.$modal.attr("data-" + o + "-id") === location.hash.substr(1) && (location.hash = "",
      n(window).scrollTop(e)),
      C(function() {
          v(a, c.CLOSING, !1, t)
      }, function() {
          a.$bg.removeClass(a.settings.modifier),
          a.$overlay.removeClass(a.settings.modifier).hide(),
          a.$wrapper.hide(),
          g(),
          v(a, c.CLOSED, !1, t)
      }, a))
  }
  ,
  N.prototype.getState = function() {
      return this.state
  }
  ,
  N.prototype.destroy = function() {
      var t, a = n[o].lookup;
      O(this),
      this.$wrapper.remove(),
      delete a[this.index],
      0 === (t = n.grep(a, function(t) {
          return !!t
      }).length) && (this.$overlay.remove(),
      this.$bg.removeClass(E("is", c.CLOSING) + " " + E("is", c.OPENING) + " " + E("is", c.CLOSED) + " " + E("is", c.OPENED)))
  }
  ,
  n[o] = {
      lookup: []
  },
  n.fn[o] = function(t) {
      var a, e;
      return this.each(function(i, s) {
          null == (e = n(s)).data(o) ? (a = new N(e,t),
          e.data(o, a.index),
          a.settings.hashTracking && e.attr("data-" + o + "-id") === location.hash.substr(1) && a.open()) : a = n[o].lookup[e.data(o)]
      }),
      a
  }
  ,
  n(document).ready(function() {
      n(document).on("click", "[data-" + o + "-target]", function(t) {
          t.preventDefault();
          var a = t.currentTarget.getAttribute("data-" + o + "-target")
            , e = n("[data-" + o + '-id="' + a + '"]');
          n[o].lookup[e.data(o)].open()
      }),
      n(document).find("." + s).each(function(t, a) {
          var e = n(a)
            , i = e.data(o + "-options");
          i ? ("string" == typeof i || i instanceof String) && (i = function t(n) {
              var a, e, i, o, s = {};
              for (o = 0,
              e = (a = (n = n.replace(/\s*:\s*/g, ":").replace(/\s*,\s*/g, ",")).split(",")).length; o < e; o++)
                  a[o] = a[o].split(":"),
                  ("string" == typeof (i = a[o][1]) || i instanceof String) && (i = "true" === i || "false" !== i && i),
                  ("string" == typeof i || i instanceof String) && (i = isNaN(i) ? i : +i),
                  s[a[o][0]] = i;
              return s
          }(i)) : i = {},
          e[o](i)
      }),
      n(document).on("keydown." + s, function(t) {
          a && a.settings.closeOnEscape && a.state === c.OPENED && 27 === t.keyCode && a.close()
      }),
      n(window).on("hashchange." + s, y)
  })
});
